/deep/ .table {
  table {
    width: 100%;
  }
  td {
    font-size: 14px;
    border: 1px solid #ddd;
    padding: 8px;
  }
}

.dialog_body {
  word-break: normal;

  .title {
    font-size: 20px;
    line-height: 1.5;
    color: $primary-purple;
    margin-bottom: 25px;
    font-weight: bold;
  }

  /deep/ {
    p {
      margin-bottom: 30px;
      font-weight: 200;
      line-height: 1.64;
      color: $primary-purple;
    }
    .date {
      color: #bad4f5;
      margin-bottom: 20px;
      text-align: center;
    }

    strong {
      font-weight: bold;
    }

    h3 {
      font-size: 1.17em;
    }

    h2 {
      font-size: 1.5em;
    }

    h4 {
      font-size: 1em;
    }

    table {
      display: flex;
      justify-content: center;
      td {
        border: 1px solid #606266;
        padding: 8px;
        color: #606266;
      }
    }
  }
}

@media (max-width: 550px) {
  .dialog_body {
    text-align: center;
  }
  .dialog_body .title {
    font-size: 16px;
  }
}
