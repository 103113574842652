/deep/ .el-dialog {
  .el-dialog__header {
    padding: 20px 20px 5px;

    img {
      width: 24px;
    }
  }

  .el-dialog__body {
    overflow-y: auto;
    word-break: normal;
    max-height: 80vh;
  }

  .el-dialog__footer {
    padding: 40px 48px;
    background-color: #f9f9f9;
    text-align: left;

    .help {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      color: $primary-purple;
      display: inline-block;
    }

    img {
      width: 24px;
      margin: 0 12px 5px 0px;
    }
  }
}

@media (max-width: 550px) {
  /deep/ .el-dialog {
    .el-dialog__header {
      img {
        width: 15px;
      }
    }

    .el-dialog__body {
      padding: 0px 12px 24px;
    }

    .el-dialog__footer {
      padding: 20px 30px;
      text-align: center;

      .help {
        font-size: 16px;
      }
    }
  }
}
