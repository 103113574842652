.header_login {
  background-color: $primary-purple;
  position: fixed;
}

img {
  display: inline-block;
}

header {
  width: 100%;
  padding: 30px 20px;
  position: sticky;
  z-index: 99;
  top: 0;

  >ul {
    margin: 0 auto;
    max-width: 1410px;

    &.maxWidth {
      max-width: 100%;
    }

    .fl {
      display: table;

      .header_btn {
        width: 25px;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
      }

      .logo {
        margin-right: 20px;
        cursor: pointer;

        .logoImg {
          background: url($logo2);
          padding: 37px 161px 0 0;
          background-size: 161px 37px;
          background-repeat: no-repeat;
        }
      }
    }

    .flag {
      padding-right: 24px;
      float: right;
      margin-top: 6px;

      &.flagLogin {
        margin-top: 0;

        .name {
          color: $primary-purple;
        }
      }

      .areaImg {
        width: 25px;
        cursor: pointer;
        vertical-align: middle;
        margin-right: 10px;
      }

      .name {
        color: white;
        margin-right: 10px;
      }
    }

    .fr {
      @include rtl-sass-prop(padding-right, padding-left, 12px);
      float: right;
      display: table;

      /deep/ .el-dropdown {
        cursor: pointer;
        vertical-align: middle;
      }

      .member_logo {
        vertical-align: middle;
        width: 25px;
      }

      .userName {
        vertical-align: text-top;
        display: inline-block;
        height: 19px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .name {
          line-height: normal;
          @include rtl-sass-value(text-align, right, left);
          color: $primary-purple;
          vertical-align: text-bottom;
        }
      }
    }

    .sign {
      min-width: 128px;
      height: 36px;
      border-radius: 23px 23px 23px 0px;
      font-family: 'GTWalsheimPro-Bold';
      border: solid 1px #38438f;
      // background-color: unset;
      color: #38438f;
      margin-right: 10px;
      font-weight: 700;
      line-height: 0.6;
      font-size: 16px;

      // &:focus {
      //   border: solid 1px #38438f;
      //   background-color: unset;
      //   color: #38438f;
      // }

      &:hover,
      &:active {
        background-color: #fff;
        // color: #fff;
      }
    }
  }
}

.login_dropdown_box {
  min-width: 182px;

  .el-dropdown-menu__item {
    padding: 15px 18px;

    .icon {
      width: 24px;
      height: 24px;
      @include rtl-sass-prop(margin-right, margin-left, 10px);
      margin-bottom: 1px;
      display: inline-block;
      vertical-align: bottom;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
    }

    &.login_back .icon {
      background-image: url(~@/assets/images/header/login_back_icon.png);
    }

    &.logout .icon {
      background-image: url(~@/assets/images/header/logout_icon.png);
    }
  }
}

.lang_dropdown_box .el-dropdown-menu__item {
  padding: 12px 24px;

  img {
    width: 24px;
    @include rtl-sass-prop(margin-right, margin-left, 15px);
  }
}

.login_dropdown_box,
.lang_dropdown_box {
  .el-dropdown-menu__item {
    line-height: normal;
    position: relative;

    //文字預設顏色
    span {
      height: 24px;
      line-height: 1.71;
      color: $primary-purple;
    }
  }
}

@media (max-width: 768px) {
  .header_login {
    background-color: $primary-purple;

    ul {
      .fl .logo .logoImg {
        background: url($logo2);
        background-size: 161px 37px;
        background-repeat: no-repeat;
      }

      .flag {
        .lang .login_lang_down {
          background: url($login_lang_down);
          background-size: 10px 10px;
          background-repeat: no-repeat;
          background-position: left;
        }

        .flagLogin_text,
        .lang .name {
          color: $white;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .header_login .flagLogin {
    float: right;
  }

  header {
    >ul {
      .fr_box {
        .userName .name {
          width: 50px;
          vertical-align: text-bottom;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .header_login ul .fl .logo .logoImg {
    padding: 26px 113px 0 0;
    background-size: 113px 26px;
  }

  header>ul {
    .flag .lang {
      padding-right: 0;
    }

    .flag {
      margin-top: 0;

      .flagLogin_text {
        border-left: 0px;
        display: none;
      }
    }
  }
}

@media (max-width: 350px) {
  header>ul {
    .fr_box {
      @include rtl-sass-prop(padding-right, padding-left, 0px);
    }
  }
}

@media (max-width: 310px) {
  header {
    padding: 10px;
  }
}